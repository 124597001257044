import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      name: '',
      is_admin: false,
      accept: false,
    }
  },
  mutations: {
    setUserName(state, payload) {
      state.user.name = payload
    },

    setUserIsAdmin(state, payload) {
      state.user.is_admin = payload
    },

    setUserAccept(state, payload) {
      state.user.accept = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
