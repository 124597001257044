<template>
  <div>
    <div id="header-layout" class="container-fluid d-flex justify-content-between justify-content-lg-end align-items-center">
      <i class="bi bi-list hover text-white d-block d-lg-none ms-2" style="font-size: 30px" data-bs-toggle="offcanvas" href="#offcanvasMenu" role="button"></i>

      <div class="d-flex align-items-center">
        <span class="text-white fw-bold me-2 d-none d-sm-block">Olá, {{ $store.state.user.name }}</span>
        <i class="bi bi-person-circle text-white" style="font-size: 40px"></i>
        <button @click="loggout()" type="button" class="btn btn-link text-white ms-2">Sair</button>
      </div>
    </div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMenu" style="width: 260px;">
      <div class="offcanvas-header" style="background: rgb(36, 36, 36)">
        <h5 class="offcanvas-title text-white">Menu</h5>
        <button type="button" class="btn btn-link fw-bold" data-bs-dismiss="offcanvas" aria-label="Close" style="color: white; text-decoration: none">X</button>
      </div>
      <div class="offcanvas-body p-0" style="background: rgb(36, 36, 36)">
        <ul class="list-group mt-5">
          <li @click="goTo('/blockeds')" class="list-group-item border-0 text-start py-3 rounded-0" style="font-size: 16px">
            <i class="bi bi-x-octagon-fill mx-3"></i> Bloqueios
          </li>
          <li v-if="$store.state.user.is_admin == true" @click="goTo('/users')" class="list-group-item border-0 text-start py-3 rounded-0" style="font-size: 16px">
            <i class="bi bi-people-fill mx-3"></i> Usuários
          </li>
          <li v-if="$store.state.user.is_admin == true" @click="goTo('/logs')" class="list-group-item border-0 text-start py-3 rounded-0" style="font-size: 16px">
            <i class="bi bi-clock-fill mx-3"></i> Histórico
          </li>
        </ul>
      </div>
    </div>
  </div> 
</template>

<script>
export default {
  name: 'Header',

  methods: {
    loggout() {
      localStorage.setItem('_token', '')
      window.location.href = "/login"
    },
    
    goTo(router) {
      window.location.href = router
    }
  }
}
</script>

<style>
#header-layout {
  width: 100%;
  height: 60px;

  z-index: 1;
  background: rgb(36, 36, 36) !important;
}
</style>