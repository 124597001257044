<template>
  <div class="modal fade" id="modalAccept" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Prezado(a) usuário(a)</h1>
        </div>
        <div class="modal-body">
          Cumpre-nos avisá-lo que, ao ingressar nesta Plataforma com login e senha, seus registros de acesso (log, IP, data e horário) ficam armazenados em nosso sistema
          para finalidade de auditoria e controle. Você tem total responsabilidade pela segurança e confidencialidade de seus dados de acesso, respondendo de forma objetiva
          caso terceiros ingressem na Plataforma com seu login e senha. Ao acessar e baixar os Relatórios e demais documentos disponíveis nesta Plataforma, você fica ciente que tais documentos contêm informações sigilosas
          e dados pessoais, devendo zelar por sua proteção e integridade, assumindo exclusiva, objetiva e integral responsabilidade civil, criminal e administrativa por vazamento de dados
          e informações, atos de tratamento de dados ilegais, na forma da Lei 13.709/2018.
        </div>
        <div class="modal-footer">
          <button @click="accept()" class="btn btn-success" type="button">Aceito</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAccept',

  props: ['modal'],

  methods: {
    async accept() {
      await this.$api.get('/auth/accept')
      .then(() => {
        this.$store.state.user.accept = true
        this.closeModal()
      })
      .catch((err) => {
        this.$toastr.e(err.response.data.message, 'Erro')
      })
    },

    closeModal() {
      this.modal.hide()
    },
  },

  watch: {
    '$parent.modalAccept'() {
      if(this.modal.shouldPreventModalClose && this.$store.state.user.accept == false) {
        this.$parent.openModalAccept()
      }
    }
  }
}
</script>

<style>
#modalAccept {
  pointer-events: none;
}
</style>