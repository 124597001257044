<template>
  <div>
    <div class="d-flex">
      <Sidebar />

      <div class="w-100">
        <Header />

        <div id="body-layout" class="p-3 w-100">
          <router-view />
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import Header from '@/components/layouts/Header'
import Sidebar from '@/components/layouts/Sidebar'

export default {
  name: 'Layout',

  components: {
    Header,
    Sidebar
  }
}
</script>

<style>
#body-layout {
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: auto;

  background: whitesmoke;
}

@media (min-width: 992px) { 
  #body-layout {
    width: calc(100% - 280px);
    height: calc(100vh - 60px);
    overflow-y: auto;

    background: whitesmoke;
  }
 }
</style>