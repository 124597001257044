<template>
  <div>
    <div class="col-12 d-flex align-items-center p-2 mt-2">
      <div class="col-12 fw-bold">Lista de Logs ({{ logs.length }})</div>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered w-100 bg-white">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Tipo</th>
            <th scope="col">Descrição</th>
            <th scope="col">Data Criação</th>
          </tr>
        </thead>
        <tbody v-if="logs.length > 0">
          <tr v-for="(log, n) in logs" :key="n">
            <th scope="row">{{ log.id }}</th>
            <td>
              <span v-if="log.type == 'Criado'" class="badge rounded-pill text-bg-success">{{ log.type }}</span>
              <span v-else-if="log.type == 'Editado'" class="badge rounded-pill text-bg-primary">{{ log.type }}</span>
              <span v-else-if="log.type == 'Excluído'" class="badge rounded-pill text-bg-danger">{{ log.type }}</span>
              <span v-else class="badge rounded-pill text-bg-dark">{{ log.type }}</span>
            </td>
            <td>{{ log.description }}</td>
            <td>{{ log.created_at }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4" class="text-center">Nenhum registro foi encontrado</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableLogs',

  props: ['logs']
}
</script>

<style>
</style>