<template>
  <div id="sidebar-layout" class="d-none d-lg-block">
    <div class="d-flex justify-content-center mt-4">
      <img src="@/assets/images/logo.png" class="hover" />
    </div>
    <h6 class="text-center text-white fw-bold mt-1">CPF Blocked</h6>
    
    <ul class="list-group mt-5">
      <li @click="goTo('/blockeds')" class="list-group-item border-0 text-start py-3 rounded-0" style="font-size: 16px">
        <i class="bi bi-x-octagon-fill mx-3"></i> Bloqueios
      </li>
      <li v-if="$store.state.user.is_admin == true" @click="goTo('/users')" class="list-group-item border-0 text-start py-3 rounded-0" style="font-size: 16px">
        <i class="bi bi-people-fill mx-3"></i> Usuários
      </li>
      <li v-if="$store.state.user.is_admin == true" @click="goTo('/logs')" class="list-group-item border-0 text-start py-3 rounded-0" style="font-size: 16px">
        <i class="bi bi-clock-fill mx-3"></i> Histórico
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',

  methods: {
    goTo(path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style>
#sidebar-layout {
  width: 280px;
  height: 100vh;

  z-index: 1;
  background: rgb(36, 36, 36);
}

.list-group-item {
  color: white !important;
  background: rgb(36, 36, 36) !important;
}

.list-group-item:hover{
  cursor: pointer;
  background: rgb(26, 26, 26) !important;
  padding-left: 25px;
}
</style>