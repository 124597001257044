<template>
  <div>
    <TableLogs :logs="logs" />
  </div>
</template>

<script>
import TableLogs from '@/components/TableLogs'

export default {
  name: 'Logs',

  data() {
    return {
      logs: []
    }
  },

  components: {
    TableLogs
  },

  methods: {
    async get() {
      await this.$api.get('/logs')
      .then((response) => {
        this.logs = response.data.logs
      })
      .catch((err) => {
        this.$toastr.e(err.response.data.message)
      })
    },
  },

  created() {
    this.get()
  }
}
</script>

<style>

</style>