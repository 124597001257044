<template>
  <div class="modal fade" id="modalDeleteFileBlocked" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Excluir CPF/CNPJ (.csv)</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <label>Você realmente deseja executar esta ação ?<br />Esta ação é <b>IRREVERSÍVEL!</b></label>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          <button ref="btnDeleteFile" @click="deleteFile()" class="btn btn-outline-danger" type="button">Enviar arquivo</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalDeleteFileBlocked',

  props: ['modal', 'file'],

  methods: {
    async deleteFile() {
      const formData = new FormData();
      formData.append('file', this.file)

      this.$refs.btnDeleteFile.innerText = 'Enviando arquivo...'
      this.$refs.btnDeleteFile.disabled = true

      await this.$api.post('/blockeds/import_delete', formData)
      .then((response) => {
        this.$toastr.s('Foi removido ' + response.data.deleted + ' bloqueio(s) da lista!', 'Sucesso')
        this.$parent.$refs.fileDelete.value = ''
        this.$parent.modalDeleteFile.file = ''
        this.$parent.get()
        this.closeModal()
      })
      .catch((err) => {
        this.$toastr.e(err.response.data.message, 'Erro')
        this.$parent.$refs.modalDeleteFile.value = ''
        this.$parent.modalDeleteFile.file = ''
      })
      .finally(() => {
        this.$refs.btnDeleteFile.innerText = 'Enviar arquivo'
        this.$refs.btnDeleteFile.disabled = false
      })
    },

    closeModal() {
      this.modal.hide()
    }
  }
}
</script>

<style>
</style>