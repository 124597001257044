<template>
  <div class="modal fade" id="modalAddFileBlocked" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Adicionar CPF/CNPJ (.csv)</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <label>Você realmente deseja executar esta ação ?<br />Esta ação é <b>IRREVERSÍVEL!</b></label>
          <label v-if="delete_old" class="mt-3"><b>OBS: Você está excluindo todos os dados antigos!</b></label>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          <button ref="btnAddFile" @click="addFile()" class="btn btn-outline-dark" type="button">Enviar arquivo</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAddFileBlocked',

  props: ['modal', 'file', 'delete_old'],

  methods: {
    async addFile() {
      const formData = new FormData();
      formData.append('file', this.file)
      formData.append('delete_old', this.delete_old)

      this.$refs.btnAddFile.innerText = 'Enviando arquivo...'
      this.$refs.btnAddFile.disabled = true

      await this.$api.post('/blockeds/import_add', formData)
      .then((response) => {
        if(this.delete_old == true) {
          this.$toastr.s('Todos bloqueios anteriores foram excluídos, e foi adicionado ' + response.data.added + ' bloqueio(s)!', 'Sucesso')
        } else {
          this.$toastr.s('Foi adicionado ' + response.data.added + ' bloqueio(s). No arquivo tinha '+ response.data.exist +' bloqueio(s) que não foram adicionados, pois já estão cadastrados!', 'Sucesso')
        }
        
        this.$parent.$refs.fileAdd.value = ''
        this.$parent.modalAddFile.file = ''
        this.$parent.modalAddFile.delete_old = false
        this.$parent.get()
        this.closeModal()
      })
      .catch((err) => {
        this.$toastr.e(err.response.data.message, 'Erro')
        this.$parent.$refs.fileAdd.value = ''
        this.$parent.modalAddFile.file = ''
      })
      .finally(() => {
        this.$refs.btnAddFile.innerText = 'Enviar arquivo'
        this.$refs.btnAddFile.disabled = false
      })
    },

    closeModal() {
      this.modal.hide()
    }
  }
}
</script>

<style>
</style>