<template>
  <div class="modal fade" id="modalEditUser" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Editar Usuário</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label m-0">Nome</label>
            <input v-model="formEdit.name" type="email" class="form-control" placeholder="Informe o nome">
          </div>
          <div class="mb-3">
            <label class="form-label m-0">E-mail</label>
            <input v-model="formEdit.email" type="email" class="form-control" placeholder="Informe o e-mail">
          </div>
          <div class="mb-3">
            <label class="form-label m-0">Senha</label>
            <input v-model="formEdit.password" type="password" class="form-control" placeholder="Informe a senha">
          </div>
          <div class="mb-3">
            <label class="form-label m-0">Confirmar senha</label>
            <input v-model="formEdit.password_confirm" type="password" class="form-control" placeholder="Confirme a senha">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          <button @click="editUser()" ref="btnEdit" type="button" class="btn btn-outline-dark">Editar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalEditUser',

  props: ['user', 'modal'],

  data() {
    return {
      formEdit: {
        id: 0,
        name: '',
        email: '',
        password: '',
        password_confirm: ''
      }
    }
  },

  methods: {
    async editUser() {
      this.$refs.btnEdit.innerText = 'Editando...'
      this.$refs.btnEdit.disabled = true

      await this.$api.put(`/users/${this.formEdit.id}`, this.formEdit)
      .then((response) => {
        this.$toastr.s('Usuário editado com sucesso!', 'Sucesso')
        const index = this.$parent.$parent.users.findIndex(user => user.id === this.formEdit.id)
        this.$parent.$parent.users.splice(index, 1, response.data.user)
        this.closeModal()
      })
      .catch((err) => {
        this.$toastr.e(err.response.data.message, 'Erro')
      })
      .finally(() => {
        this.$refs.btnEdit.innerText = 'Editar'
        this.$refs.btnEdit.disabled = false
      })
    },

    closeModal() {
      this.modal.hide()
    },
  },

  watch: {
    modal() {
      this.formEdit.id = this.user.id
      this.formEdit.name = this.user.name
      this.formEdit.email = this.user.email
    }
  }
}
</script>

<style>
</style>