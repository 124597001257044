<template>
  <div>
    <div class="row d-flex align-items-center p-2 mt-5">
      <div class="col-12 col-lg-6 fw-bold mt-2">Lista de Bloqueados ({{ count_blockeds }})</div>
      <div class="col-12 col-lg-6 mt-2">
        <div class="input-group">
          <input @keypress.enter="$parent.get()" type="text" class="form-control" placeholder="Procurar" :value="value" @input="$emit('input', $event.target.value)">
          <button @click="$parent.get()" class="btn btn-secondary" type="button">Buscar</button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered w-100 bg-white">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">CPF/CNPJ</th>
            <th scope="col">Data Criação</th>
            <th scope="col text-center">Ação</th>
          </tr>
        </thead>
        <tbody v-if="blockeds.length > 0">
          <tr v-for="(blocked, n) in blockeds" :key="n">
            <th scope="row">{{ blocked.id }}</th>
            <td>
              <span v-if="blocked.cpf_cnpj.length == 11">{{ blocked.cpf_cnpj | VMask('###.###.###-##') }}</span>
              <span v-else>{{ blocked.cpf_cnpj | VMask('##.###.###/####-##') }}</span>
            </td>
            <td>{{ blocked.created_at }}</td>
            <td>
              <button type="button" class="btn btn-sm btn-outline-danger" @click="openModalDelete(blocked.id, blocked.cpf_cnpj)">
                <i class="bi bi-trash-fill"></i> Excluir
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4" class="text-center">Nenhum registro foi encontrado. Faça uma busca!</td>
          </tr>
        </tbody>
      </table>
    </div>

    <ModalDeleteBlocked :id="modalDelete.id" :text="modalDelete.text" :modal="modalDelete.modal"/>
  </div>
</template>

<script>
import { Modal } from "bootstrap"
import ModalDeleteBlocked from '@/components/ModalDeleteBlocked'

export default {
  name: 'TableBlockeds',

  props: ['blockeds', 'value', 'count_blockeds'],

  components: {
    ModalDeleteBlocked
  },

  data() {
    return {
      modalDelete: {
        modal: null,
        id: 0,
        text: '',
      }
    }
  },

  methods: {
    openModalDelete(id, text) {
      const modalDelete = new Modal(document.getElementById('modalDeleteBlocked'))
      
      this.modalDelete.id = id
      this.modalDelete.text = text
      this.modalDelete.modal = modalDelete
      modalDelete.show()
    }
  }
}
</script>

<style>
</style>