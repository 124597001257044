<template>
  <div class="modal fade" id="modalDeleteUser" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Excluir Usuário</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Você realmente deseja excluir o usuário <b>{{ name }}</b> ID:[{{id}}]
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          <button ref="btnDelete" @click="deleteUser()" type="button" class="btn btn-outline-danger">Excluir</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalDeleteUser',

  props: ['id', 'name', 'modal'],

  methods: {
    async deleteUser() {
      this.$refs.btnDelete.innerText = 'Excluindo...'
      this.$refs.btnDelete.disabled = true

      await this.$api.delete(`/users/${this.id}`)
      .then((response) => {
        this.$toastr.s('Usuário excluído com sucesso!', 'Sucesso')
        this.$parent.$parent.users = this.$parent.$parent.users.filter((obj) => obj.id !== response.data.user.id)
        this.closeModalDelete()
      })
      .catch((err) => {
        this.$toastr.e(err.response.data.message, 'Erro')
      })
      .finally(() => {
        this.$refs.btnDelete.innerText = 'Excluir'
        this.$refs.btnDelete.disabled = false
      })
    },

    closeModalDelete() {
      this.modal.hide()
    }
  }
}
</script>

<style>
</style>