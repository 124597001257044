import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './services/axios'
import VueToastr from "vue-toastr";
import VueMask from 'v-mask'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-icons/font/bootstrap-icons'

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.use(VueToastr)
Vue.use(VueMask);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
