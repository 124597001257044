<template>
  <div>
    <div class="row d-flex align-items-center p-2 mt-2">
      <div class="col-8 col-lg-6 fw-bold">Lista de Usuários ({{ users.length }})</div>
      <div class="col-4 col-lg-6 d-flex justify-content-end">
        <button @click="openModalAdd()" type="button" class="btn btn-dark ms-4">Novo usuário</button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered w-100 bg-white">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Nome</th>
            <th scope="col">Email</th>
            <th scope="col">Tipo</th>
            <th scope="col">Bloqueios</th>
            <th scope="col text-center">Ações</th>
          </tr>
        </thead>
        <tbody v-if="users.length > 0">
          <tr v-for="(user, n) in users" :key="n">
            <th scope="row">{{ user.id }}</th>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.is_admin == true ? 'Administrador' : 'Usuário' }}</td>
            <td>{{ user.count_blockeds }}</td>
            <td class="d-flex">
              <button v-if="user.id != 1" type="button" class="btn btn-sm btn-outline-dark me-2" @click="openModalEdit(user)">
                <i class="bi bi-pen-fill"></i> Editar
              </button>
              <button v-else type="button" class="btn btn-sm btn-secondary me-2" disabled><i class="bi bi-pen-fill"></i> Editar</button>

              <button v-if="user.id != 1" type="button" class="btn btn-sm btn-outline-danger" @click="openModalDelete(user)">
                <i class="bi bi-trash-fill"></i> Excluir
              </button>
              <button v-else type="button" class="btn btn-sm btn-secondary" disabled><i class="bi bi-trash-fill"></i> Excluir</button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4" class="text-center">Nenhum registro foi encontrado</td>
          </tr>
        </tbody>
      </table>
    </div>

    <ModalAddUser :modal="modalAdd.modal" />
    <ModalEditUser :user="modalEdit.user" :modal="modalEdit.modal" />
    <ModalDeleteUser :id="modalDelete.user.id" :name="modalDelete.user.name" :modal="modalDelete.modal" />
  </div>
</template>

<script>
import { Modal } from "bootstrap"
import ModalAddUser from '@/components/ModalAddUser'
import ModalEditUser from '@/components/ModalEditUser'
import ModalDeleteUser from '@/components/ModalDeleteUser'

export default {
  name: 'TableUsers',

  props: ['users', 'value'],

  components: {
    ModalAddUser,
    ModalEditUser,
    ModalDeleteUser
  },

  data() {
    return {
      modalAdd: {
        modal: null
      },

      modalEdit: {
        modal: null,
        user: null
      },

      modalDelete: {
        modal: null,
        user: {
          id: '',
          name: '',
        }
      }
    }
  },

  methods: {
    openModalAdd() {
      const modalAdd = new Modal(document.getElementById('modalAddUser'))

      this.modalAdd.modal = modalAdd
      modalAdd.show()
    },

    openModalEdit(user) {
      const modalEdit = new Modal(document.getElementById('modalEditUser'))

      this.modalEdit.user = user
      this.modalEdit.modal = modalEdit
      modalEdit.show()
    },

    openModalDelete(user) {
      const modalDelete = new Modal(document.getElementById('modalDeleteUser'))
      
      this.modalDelete.user.id = user.id
      this.modalDelete.user.name = user.name
      this.modalDelete.modal = modalDelete
      modalDelete.show()
    }
  }
}
</script>

<style>
</style>