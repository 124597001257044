import Vue from 'vue'
import VueRouter from 'vue-router'
import middleware from '@/services/middleware'

import Layout from '@/components/layouts/Layout'
import Login from '@/views/Login'
import Blockeds from '@/views/Blockeds'
import Users from '@/views/Users'
import Logs from '@/views/Logs'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    beforeEnter: middleware.auth,
    children: [
      {
        path: '/',
        redirect: '/blockeds'
      },
      {
        path: '/blockeds',
        name: 'Blockeds',
        component: Blockeds,
      },
      {
        path: '/users',
        name: 'Users',
        component: Users,
        beforeEnter: middleware.admin
      },
      {
        path: '/logs',
        name: 'Logs',
        component: Logs,
        beforeEnter: middleware.admin
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: middleware.guest
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
