<template>
  <div>
    <div class="container p-0 h-100 d-flex justify-content-center my-5">
      <div id="page-login-right" class="col-12 col-lg-6 pt-2">
        <div class="d-flex justify-content-center align-items-center mt-5">
          <img src="@/assets/images/logo.png" width="236" height="35">
        </div>
        <h6 class="fw-bold text-center text-white mt-1">CPF's bloqueados</h6>

        <div class="row m-0 d-flex flex-column mt-5">
          <div class="d-flex justify-content-center">
            <div class="mb-4 col-10 col-md-8 col-lg-6 d-block">
              <label class="form-label text-white m-0">E-mail</label>
              <input v-model="form.email" type="email" class="form-control" />
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="mb-4 col-10 col-md-8 col-lg-6">
              <label class="form-label text-white m-0">Senha</label>
              <input @keypress.enter="login()" v-model="form.password" type="password" class="form-control" />
            </div>
          </div>

          <div class="d-flex justify-content-center mt-3 mb-5">
            <div class="col-10 col-md-8 col-lg-6">
              <button @click="login()" @keyup.enter="login" ref="btnLogin" class="btn btn-secondary w-100 fw-bold"
                type="submit">Entrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',

  data() {
    return {
      form: {
        email: '',
        password: ''
      },

      logged: false
    }
  },

  methods: {
    async login() {
      const formData = new FormData();
      formData.append('email', this.form.email)
      formData.append('password', this.form.password)

      this.$refs.btnLogin.innerText = 'Entrando...'
      this.$refs.btnLogin.disabled = true

      await this.$api.post('/auth/login', formData)
        .then((response) => {
          localStorage.setItem('_token', response.data.token)
          this.$toastr.s('Logado com sucesso!', 'Sucesso')
          this.logged = true
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
          this.form.password = ''
        })
        .finally(() => {
          this.$refs.btnLogin.innerText = 'Entrar'
          this.$refs.btnLogin.disabled = false
        })

      if (this.logged == true) {
        window.location.href = "/blockeds"
      }
    }
  }
}
</script>

<style scoped>
#page-login-right {
  border-radius: 20px;
  background: rgb(24, 24, 24);
}
</style>