import api from '@/services/axios'
import store from '@/store/index'

export default {
  async auth(to, from, next) {
    await api.get('/auth/me').then((response) => {
      store.commit('setUserName', response.data.user.name)
      store.commit('setUserIsAdmin', response.data.user.is_admin)
      store.commit('setUserAccept', response.data.user.accept)
      next() 
    })
    .catch(() => {
      next({
          path: '/login'
      })
    })
  },

  async guest(to, from, next) {
    await api.get('/auth/me').then(() => {
      next({
        path: '/blockeds'
      })
    })
    .catch(() => {
      next()
    })
  },

  async admin(to, from, next) {
    await api.get('/auth/me').then((response) => {

      if(response.data.user.is_admin == true) {
        next() 
      } else {
        next({
          path: '/blockeds'
        })
      }
      
    })
    .catch(() => {
      next({
          path: '/blockeds'
      })
    })
  }
}