<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-6 mb-4">
        <div class="bg-white border p-3 rounded">
          <h6 class="fw-bold mb-4">Adicionar CPF/CNPJ impedidos em lote</h6>

          <div class="input-group">
            <input ref="fileAdd" type="file" class="form-control" @change="onFileAddChange">
            <button @click="openModalAddFile()" class="btn btn-dark" type="button">Enviar arquivo</button>
          </div>

          <div class="form-check mt-1">
            <input v-model="modalAddFile.delete_old" class="form-check-input" type="checkbox">
            <label class="form-check-label">
              Excluir dados antigos e manter apenas o novo arquivo
            </label>
          </div>

          <div class="d-flex justify-content-end mt-3">
            <button @click="downloadExemple()" type="button" class="btn btn-secondary">Baixar planilha modelo</button>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6 mb-4">
        <div class="bg-white border p-3 rounded h-100">
          <h6 class="fw-bold mb-4">Adicionar CPF/CNPJ impedidos individualmente</h6>

          <label class="form-label m-0">CPF/CNPJ</label>
          <div class="input-group mb-3">
            <input v-if="formAdd.cpf_cnpj.length <= 14" @keypress.enter="add()" v-model="formAdd.cpf_cnpj"
              v-mask="'###.###.###-##'" type="text" class="form-control"
              placeholder="Informe o CPF ou CNPJ que deseja bloquear">
            <input v-else @keypress.enter="add()" v-model="formAdd.cpf_cnpj" v-mask="'##.###.###/####-##'" type="text"
              class="form-control" placeholder="Informe o CPF ou CNPJ que deseja bloquear">
            <button @click="add()" ref="btnAdd" class="btn btn-dark" type="button">Adicionar</button>
          </div>
        </div>
      </div>

      <div class="col-12 mb-4">
        <div class="bg-white border p-3 rounded">
          <h6 class="fw-bold mb-4">Excluir CPF/CNPJ impedidos em lote</h6>

          <div class="input-group">
            <input ref="fileDelete" type="file" class="form-control" @change="onFileDeleteChange">
            <button @click="openModalDeleteFile()" class="btn btn-outline-danger" type="button">Enviar arquivo</button>
          </div>
        </div>
      </div>
    </div>

    <hr class="mt-5">

    <ModalAccept :modal="modalAccept.modal" />
    <TableBlockeds v-model="search" :blockeds="blockeds" :count_blockeds="count_blockeds" />
    <ModalAddFileBlocked :modal="modalAddFile.modal" :file="modalAddFile.file" :delete_old="modalAddFile.delete_old" />
    <ModalDeleteFileBlocked :modal="modalDeleteFile.modal" :file="modalDeleteFile.file" />
  </div>
</template>

<script>
import { Modal } from "bootstrap"
import ModalAccept from '@/components/ModalAccept'
import TableBlockeds from '@/components/TableBlockeds'
import ModalAddFileBlocked from '@/components/ModalAddFileBlocked'
import ModalDeleteFileBlocked from '@/components/ModalDeleteFileBlocked'

export default {
  name: 'Blockeds',

  components: {
    ModalAccept,
    TableBlockeds,
    ModalAddFileBlocked,
    ModalDeleteFileBlocked
  },

  data() {
    return {
      formAdd: {
        cpf_cnpj: '',
      },

      modalAddFile: {
        modal: null,
        file: '',
        delete_old: false
      },

      modalDeleteFile: {
        modal: null,
        file: ''
      },

      modalAccept: {
        modal: null
      },

      blockeds: [],
      count_blockeds: 0,
      search: '',
      showDelete: false,
    }
  },

  methods: {
    async get() {
      await this.$api.get(`/blockeds?search=${this.search}`)
        .then((response) => {
          this.blockeds = response.data.blockeds
          this.count_blockeds = response.data.count_blockeds
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message)
        })
    },

    async add() {
      const formData = new FormData();
      formData.append('cpf_cnpj', this.formAdd.cpf_cnpj)

      this.$refs.btnAdd.innerText = 'Adicionando...'
      this.$refs.btnAdd.disabled = true

      await this.$api.post('/blockeds', formData)
        .then((response) => {
          this.$toastr.s('CPF/CNPJ adicionado com sucesso!', 'Sucesso')
          this.formAdd.cpf_cnpj = ''
          this.blockeds.unshift(response.data.blocked)
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message, 'Erro')
        })
        .finally(() => {
          this.$refs.btnAdd.innerText = 'Adicionar'
          this.$refs.btnAdd.disabled = false
        })
    },

    openModalAddFile() {
      if (this.modalAddFile.file != '') {
        const modalAddFile = new Modal(document.getElementById('modalAddFileBlocked'))
        this.modalAddFile.modal = modalAddFile
        modalAddFile.show()
      } else {
        this.$toastr.e('Faça upload do arquivo!', 'Erro')
      }
    },

    openModalDeleteFile() {
      if (this.modalDeleteFile.file != '') {
        const modalDeleteFile = new Modal(document.getElementById('modalDeleteFileBlocked'))
        this.modalDeleteFile.modal = modalDeleteFile
        modalDeleteFile.show()
      } else {
        this.$toastr.e('Faça upload do arquivo!', 'Erro')
      }
    },

    openModalAccept() {
      const modalAccept = new Modal(document.getElementById('modalAccept'))
      this.modalAccept.modal = modalAccept
      modalAccept.show()
    },

    onFileAddChange(e) {
      this.modalAddFile.file = e.target.files[0]
    },

    onFileDeleteChange(e) {
      this.modalDeleteFile.file = e.target.files[0]
    },

    downloadExemple() {
      window.open('https://rpm.com.br/exemplo/excel_exemplo.xlsx', '_blank');
      window.focus();
    }
  },

  created() {
    this.get()
  },

  mounted() {
    if (this.$store.state.user.accept == false) {
      this.openModalAccept()
    }
  }
}
</script>

<style></style>